<template>
  <div>
    <save-bar
      :loading="loading"
      :isDirty="isDirty"
      @cancel="cancelClick"
      @save="saveClick"
      class="bottom"
    />
    <div class="page-inner">
      <template-body
        ref="body"
        @is-dirty="(b) => (isDirty = b)"
        @loading="loading = 1"
        @done="loading = 0"
      />
    </div>
  </div>
</template>

<script>
import SaveBar from './subnav/SaveBar.vue'
import PageMixin from '../mixins/Page'
import TemplateBody from '../bodies/Template.vue'
import eventBus from '../../eventBus'

export default {
  name: 'TemplateNewPage',
  data() {
    return {
      loading: 0,
      isDirty: 0
    }
  },
  mixins: [PageMixin],
  watch: {},
  computed: {},
  methods: {
    cancelClick() {
      this.$refs.body.reset().then(() => {
        this.isDirty = false
        eventBus.$emit('back', '/templates')
      })
    },
    saveClick() {
      this.$refs.body.save()
    }
  },
  components: {
    TemplateBody,
    SaveBar
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
